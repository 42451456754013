const COMBUSTION = [
	{ label: 'Gasolina', emission: 2.16, unit: 'litres' },
	{ label: 'Gasoil', emission: 2.55, unit: 'litres' },
	{ label: 'Fuel Domèstic', emission: 2.604, unit: 'litres' },
	{ label: 'Gas propà', emission: 2.94, unit: 'kg' },
	{ label: 'Gas butà', emission: 2.96, unit: 'kg' },
	{ label: 'Biomassa', emission: 0, unit: 'kg' },
];

const ENERGY = [
	{ label: 'Electricitat de la xarxa', emission: 0.124, losses: 0.0505, unit: 'kWh' },
	{ label: 'Electricitat renovable (autoconsum)', emission: 0, unit: 'kWh' },
	{ label: 'Calor cogeneració GNL', emission: 0.236, unit: 'kWh' },
	{ label: 'Calor cogeneració CTRASA', emission: 0.108, unit: 'kWh' },
  { label: 'Calor cogeneració biomassa', emission: 0, unit: 'kWh' },
];

const GAS = [
	{ label: 'Perfluorometà', emission: 7390, unit: 'kg' },
	{ label: 'Perfluoroetà', emission: 12200, unit: 'kg' },
	{ label: 'Perfluoropropà', emission: 8830, unit: 'kg' },
	{ label: 'Perfluorobutà', emission: 8860, unit: 'kg' },
	{ label: 'Perfluorociclobutà', emission: 10300, unit: 'kg' },
	{ label: 'Perfluoropentà', emission: 9160, unit: 'kg' },
	{ label: 'Perfluorohexà', emission: 9300, unit: 'kg' },
	{ label: 'Hexafluorur de sofre', emission: 22800, unit: 'kg' },
	{ label: 'HFC-125', emission: 3500, unit: 'kg' },
	{ label: 'HFC-134', emission: 1100, unit: 'kg' },
	{ label: 'HFC-134a', emission: 1430, unit: 'kg' },
	{ label: 'HFC-143', emission: 353, unit: 'kg' },
	{ label: 'HFC-143a', emission: 4470, unit: 'kg' },
	{ label: 'HFC-152', emission: 53, unit: 'kg' },
	{ label: 'HFC-152a', emission: 124, unit: 'kg' },
	{ label: 'HFC-161', emission: 12, unit: 'kg' },
	{ label: 'HFC-227ea', emission: 3220, unit: 'kg' },
	{ label: 'HFC-23', emission: 14800, unit: 'kg' },
	{ label: 'HFC-236cb', emission: 1340, unit: 'kg' },
	{ label: 'HFC-236ea', emission: 1370, unit: 'kg' },
	{ label: 'HFC-236fa', emission: 9810, unit: 'kg' },
	{ label: 'HFC-245ca', emission: 693, unit: 'kg' },
	{ label: 'HFC-245fa', emission: 1030, unit: 'kg' },
	{ label: 'HFC-32', emission: 675, unit: 'kg' },
	{ label: 'HFC-365mfc', emission: 794, unit: 'kg' },
	{ label: 'HFC-41', emission: 92, unit: 'kg' },
	{ label: 'HFC-43-10mee', emission: 1640, unit: 'kg' },
	{ label: 'R-404A', emission: 3921.6, unit: 'kg' },
	{ label: 'R-407A', emission: 2107, unit: 'kg' },
	{ label: 'R-407B', emission: 2803.5, unit: 'kg' },
	{ label: 'R-407C', emission: 1773.85, unit: 'kg' },
	{ label: 'R-407F', emission: 2032, unit: 'kg' },
	{ label: 'R-410A', emission: 2087.5, unit: 'kg' },
	{ label: 'R-410B', emission: 2228.75, unit: 'kg' },
	{ label: 'R-413A', emission: 1258, unit: 'kg' },
	{ label: 'R-417A', emission: 2325, unit: 'kg' },
	{ label: 'R-417B', emission: 3026, unit: 'kg' },
	{ label: 'R-422A', emission: 3143, unit: 'kg' },
	{ label: 'R-422D', emission: 2729, unit: 'kg' },
	{ label: 'R-424A', emission: 2440, unit: 'kg' },
	{ label: 'R-426A', emission: 1508, unit: 'kg' },
	{ label: 'R-427A', emission: 2138, unit: 'kg' },
	{ label: 'R-428A', emission: 3607, unit: 'kg' },
	{ label: 'R-434A', emission: 3245, unit: 'kg' },
	{ label: 'R-437A', emission: 1805, unit: 'kg' },
	{ label: 'R-438A', emission: 2264, unit: 'kg' },
	{ label: 'R-442A', emission: 1888, unit: 'kg' },
	{ label: 'R-507A', emission: 3985, unit: 'kg' },
];

const WASTE = [ // density = kg / bag (considering 30l bags)
	{ label: 'Vidre', emission: 0.031, unit: 'kg', density: 8.1 },
	{ label: 'Envasos', emission: 0.120, unit: 'kg', density: 0.6 },
	{ label: 'Paper/cartró', emission: 0.056, unit: 'kg', density: 0.9 },
	{ label: 'Fracció resta', emission: 0.169, unit: 'kg', density: 3.9 },
	{ label: 'Metalls', emission: 0.120, unit: 'kg', extended: true },
	{ label: 'Metall fèrric', emission: 0.120, unit: 'kg', extended: true },
	{ label: 'Metalls mesclats', emission: 0.120, unit: 'kg', extended: true },
	{ label: 'Residus de pintura i vernís', emission: 1.967, unit: 'kg', extended: true },
	{ label: 'Olis minerals de motor', emission: 2.848, unit: 'kg', extended: true },
	{ label: 'Olis sintètics de motor', emission: 2.848, unit: 'kg', extended: true },
	{ label: 'Mescla de residus procedents de separador hidrocarburs', emission: 2.093, unit: 'kg', extended: true },
	{ label: 'Envasos que contenen o estan contaminats per restes de substàncies perilloses', emission: 2.392, unit: 'kg', extended: true },
	{ label: 'Gasos en recipients a pressió', emission: 2.378, unit: 'kg', extended: true },
	{ label: 'Bateries de plom', emission: 1.444, unit: 'kg', extended: true },
	{ label: 'Bateries i acumuladors', emission: 1.444, unit: 'kg', extended: true },
	{ label: 'Tubs fluorescents i altres residus que contenen mercuri', emission: 0.131, unit: 'kg', extended: true },
	{ label: 'Equips elèctric i electrònics', emission: 0.053, unit: 'kg', extended: true },
	{ label: 'Fracció mineral, escories', emission: 0, unit: 'kg', extended: true },
	{ label: 'Cendres', emission: 0.073, unit: 'kg', extended: true },
	{ label: 'Altres bases', emission: 1.967, unit: 'kg', extended: true },
];

const WATER = [{ label: 'Xarxa general', emission: 0.131, unit: 'm3' }];

export { COMBUSTION, ENERGY, WASTE, WATER, GAS };
