import { mapGetters } from 'vuex';

export default {
	props: {
		sector: {
			type: String,
			required: true,
		},
	},
	data() {
		return { valid: {} };
	},
	computed: {
		...mapGetters(['getDetails']),
		isValid() {
			return Object.values(this.valid).reduce((acc, isValid) => acc && isValid, true);
		},
	},
	watch: {
		isValid: {
			immediate: true,
			handler(isValid) { this.$emit('validate', isValid); },
		},
	},
};
