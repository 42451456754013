<template lang="html">
	<section class="block">
		<h3>{{ $t(`${sector}.${abast}.spectators.title`) }}</h3>
		<p v-if="isReportEditable" class="note">{{ $t(`${sector}.${abast}.spectators.desc`) }}</p>
		<emissions-table v-if="!skip" v-model="emissions" :editable="isReportEditable">
			<template v-slot:header>
				<th>{{ $t('report.table.concept') }}</th>
				<th>{{ $t('report.table.people') }}</th>
				<th>{{ $t('report.table.vehicle') }}</th>
				<th>
					<span :data-tooltip-alert="$t('tooltip.km_vs_hour')">
						<i class="mdi mdi-alert-circle icon icon--emphasis" />
					</span>
					{{ $t('report.table.distance') }}
				</th>
			</template>
			<template v-slot:body="{ item }">
				<td>{{ item.concept }}</td>
				<td>{{ item.qty }}<em class="unit">{{ item.resource.unit }}</em></td>
				<td>{{ item.vehicle.label }}</td>
				<td>{{ item.mult }}<em class="unit">{{ vehicleUnit(item.vehicle) }}</em>
				</td>
			</template>
			<template v-slot:insert="{ insert }">
				<td>
					<input
						type="text"
						class="long"
						v-model="insert.concept"
						:placeholder="$t(`${sector}.${abast}.spectators.example`)" />
				</td>
				<td>
					<input
						v-model.number="insert.qty"
						:placeholder="$t('report.table.people')"
						type="number"
						min="0"
						step="1" />
				</td>
				<td>
					<dropdown-select
						:options="VEHICLES"
						v-model="insert.vehicle"
						@input="insert.resource = insert.vehicle.emissions.person"
						:placeholder="$t('report.table.vehicle')" />
				</td>
				<td :data-tooltip="insert.vehicle
					? $t('tooltip.unit_in', {
							unit: insert.vehicle.timed
								? $t('global.default.hours')
								: $t('global.default.km')
						})
					: $t('tooltip.require.vehicle')">
					<input
						v-model.number="insert.mult"
						:disabled="!insert.vehicle"
						:placeholder="insert.vehicle
							? insert.vehicle.timed
								? $t('global.default.hours')
								: $t('global.default.km')
							: $t('report.table.distance')"
						type="number"
						min="0"
						step="0.1"/>
				</td>
			</template>
		</emissions-table>
		<p v-if="isSkipperVisible">
			<label>
				<input type="checkbox" v-model="skip" :disabled="!isReportEditable" />
				{{ $t('global.tip.skip') }}
			</label>
		</p>
	</section>
</template>

<script>
import StepBlockMixin from '@/mixins/StepBlockMixin';
import EmissionsTable from '@/components/EmissionsTable.vue';
import DropdownSelect from '@/components/DropdownSelect.vue';
import VEHICLES from '@/assets/constants/vehicles';

export default {
	name: 'StepSpectators',
  keyBlock: 'spectators',
	mixins: [StepBlockMixin],
	components: { EmissionsTable, DropdownSelect },
	data() {
		return { VEHICLES: VEHICLES.filter((vehicle) => vehicle.passengers) };
	},
	methods: {
		vehicleUnit(vehicle) {
			return this.$t(`global.default.${vehicle.timed ? 'hours' : 'km'}`);
		},
	},
};
</script>
