export default [
	{
		label: 'Hosteleria',
		items: [
			{ label: 'Nit hostal o càmping', emission: 8, unit: 'nits' },
			{ label: 'Nit hotel *', emission: 9, unit: 'nits' },
			{ label: 'Nit hotel **', emission: 11.2, unit: 'nits' },
			{ label: 'Nit hotel ***', emission: 22.3, unit: 'nits' },
			{ label: 'Nit hotel **** / *****', emission: 44.3, unit: 'nits' },
		],
	},
	{
		label: 'Restauració',
		items: [
			{ label: 'Got de plàstic', emission: 0.03976, unit: 'unitats' },
			{ label: 'Refrigeri', emission: 1.01505, unit: 'refrigeris' },
			{ label: 'Àpat', emission: 3.24816, unit: 'àpats' },
		],
	},
	{
		label: 'Marxandatge',
		items: [
			{ label: 'Bolígraf', emission: 0.052, unit: 'unitats' },
			{ label: 'Got de plàstic', emission: 0.03976, unit: 'unitats' },
			{ label: 'Samarreta', emission: 3.3465, unit: 'unitats' },
			{ label: 'Pantaló', emission: 4.462, unit: 'unitats' },
			{ label: 'Bossa de roba', emission: 3.3465, unit: 'unitats' },
			{ label: 'Memòria USB', emission: 5.05, unit: 'unitats' },
		],
	},
	{
		label: 'Impremta',
		items: [
			{ label: 'Acreditació', emission: 0.0474, unit: 'unitats' },
			{ label: 'Publicació DINA0', emission: 0.09286, unit: 'pàgines' },
			{ label: 'Publicació DINA1', emission: 0.04643, unit: 'pàgines' },
			{ label: 'Publicació DINA2', emission: 0.023215, unit: 'pàgines' },
			{ label: 'Publicació DINA3', emission: 0.0116075, unit: 'pàgines' },
			{ label: 'Publicació DINA4', emission: 0.00580375, unit: 'pàgines' },
			{ label: 'Publicació DINA5', emission: 0.002901875, unit: 'pàgines' },
		],
	},
];
