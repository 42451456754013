<template lang="html">
	<section class="block">
		<h3>{{ $t(`${sector}.${abast}.energy.title`) }}</h3>
		<p v-if="isReportEditable" class="note">{{ $t(`${sector}.${abast}.energy.desc`) }}</p>
		<emissions-table v-if="!skip" v-model="emissions" :editable="isReportEditable">
			<template v-slot:header>
				<th>{{ $t('report.table.concept') }}</th>
				<th>{{ $t('report.table.energy_source') }}</th>
				<th>{{ $t('report.table.consumption') }}</th>
			</template>
			<template v-slot:body="{ item }">
				<td>{{ item.concept }}</td>
				<td>{{ item.resource.label }}</td>
				<td>{{ item.qty }}<em class="unit">{{ item.resource.unit }}</em></td>
			</template>
			<template v-slot:insert="{ insert }">
				<td>
					<input
						type="text"
						class="long"
						v-model="insert.concept"
						:placeholder="$t(`${sector}.${abast}.energy.example`)" />
				</td>
				<td>
					<dropdown-select
						:options="ENERGY"
						v-model="insert.resource"
						:placeholder="$t('report.table.energy_source')" />
				</td>
				<td :data-tooltip="isEmpty(insert.resource)
					? $t('tooltip.require.energy_source')
					: $t('tooltip.unit_in', { unit: insert.resource.unit })">
					<input
						v-model.number="insert.qty"
						:disabled="isEmpty(insert.resource)"
						:placeholder="isEmpty(insert.resource)
							? $t('report.table.qty')
							: insert.resource.unit"
						type="number"
						min="0"
						step="0.1" />
				</td>
			</template>
		</emissions-table>
		<p v-if="isSkipperVisible">
			<label>
				<input type="checkbox" v-model="skip" :disabled="!isReportEditable" />
				{{ $t('global.tip.skip') }}
			</label>
		</p>
	</section>
</template>

<script>
import StepBlockMixin from '@/mixins/StepBlockMixin';
import EmissionsTable from '@/components/EmissionsTable.vue';
import DropdownSelect from '@/components/DropdownSelect.vue';
import { isEmpty } from '@/utils';
import { ENERGY } from '@/assets/constants/resources';

export default {
	name: 'StepEnergy',
  keyBlock: 'energy',
	mixins: [StepBlockMixin],
	components: { EmissionsTable, DropdownSelect },
	data() {
		return { ENERGY };
	},
	methods: { isEmpty },
};
</script>
