var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"block"},[_c('h3',[_vm._v(_vm._s(_vm.$t((_vm.sector + "." + _vm.abast + ".spectators.title"))))]),(_vm.isReportEditable)?_c('p',{staticClass:"note"},[_vm._v(_vm._s(_vm.$t((_vm.sector + "." + _vm.abast + ".spectators.desc"))))]):_vm._e(),(!_vm.skip)?_c('emissions-table',{attrs:{"editable":_vm.isReportEditable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('th',[_vm._v(_vm._s(_vm.$t('report.table.concept')))]),_c('th',[_vm._v(_vm._s(_vm.$t('report.table.people')))]),_c('th',[_vm._v(_vm._s(_vm.$t('report.table.vehicle')))]),_c('th',[_c('span',{attrs:{"data-tooltip-alert":_vm.$t('tooltip.km_vs_hour')}},[_c('i',{staticClass:"mdi mdi-alert-circle icon icon--emphasis"})]),_vm._v(" "+_vm._s(_vm.$t('report.table.distance'))+" ")])]},proxy:true},{key:"body",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.concept))]),_c('td',[_vm._v(_vm._s(item.qty)),_c('em',{staticClass:"unit"},[_vm._v(_vm._s(item.resource.unit))])]),_c('td',[_vm._v(_vm._s(item.vehicle.label))]),_c('td',[_vm._v(_vm._s(item.mult)),_c('em',{staticClass:"unit"},[_vm._v(_vm._s(_vm.vehicleUnit(item.vehicle)))])])]}},{key:"insert",fn:function(ref){
var insert = ref.insert;
return [_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(insert.concept),expression:"insert.concept"}],staticClass:"long",attrs:{"type":"text","placeholder":_vm.$t((_vm.sector + "." + _vm.abast + ".spectators.example"))},domProps:{"value":(insert.concept)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(insert, "concept", $event.target.value)}}})]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(insert.qty),expression:"insert.qty",modifiers:{"number":true}}],attrs:{"placeholder":_vm.$t('report.table.people'),"type":"number","min":"0","step":"1"},domProps:{"value":(insert.qty)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(insert, "qty", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('td',[_c('dropdown-select',{attrs:{"options":_vm.VEHICLES,"placeholder":_vm.$t('report.table.vehicle')},on:{"input":function($event){insert.resource = insert.vehicle.emissions.person}},model:{value:(insert.vehicle),callback:function ($$v) {_vm.$set(insert, "vehicle", $$v)},expression:"insert.vehicle"}})],1),_c('td',{attrs:{"data-tooltip":insert.vehicle
				? _vm.$t('tooltip.unit_in', {
						unit: insert.vehicle.timed
							? _vm.$t('global.default.hours')
							: _vm.$t('global.default.km')
					})
				: _vm.$t('tooltip.require.vehicle')}},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(insert.mult),expression:"insert.mult",modifiers:{"number":true}}],attrs:{"disabled":!insert.vehicle,"placeholder":insert.vehicle
						? insert.vehicle.timed
							? _vm.$t('global.default.hours')
							: _vm.$t('global.default.km')
						: _vm.$t('report.table.distance'),"type":"number","min":"0","step":"0.1"},domProps:{"value":(insert.mult)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(insert, "mult", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])]}}],null,false,2458011967),model:{value:(_vm.emissions),callback:function ($$v) {_vm.emissions=$$v},expression:"emissions"}}):_vm._e(),(_vm.isSkipperVisible)?_c('p',[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.skip),expression:"skip"}],attrs:{"type":"checkbox","disabled":!_vm.isReportEditable},domProps:{"checked":Array.isArray(_vm.skip)?_vm._i(_vm.skip,null)>-1:(_vm.skip)},on:{"change":function($event){var $$a=_vm.skip,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.skip=$$a.concat([$$v]))}else{$$i>-1&&(_vm.skip=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.skip=$$c}}}}),_vm._v(" "+_vm._s(_vm.$t('global.tip.skip'))+" ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }